import AdsBanner from "@components/AdsBanner/AdsBanner"
import AutoPopup from "@components/AutoPopup/AutoPopup"
import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import FAQ from "@components/FAQ/FAQ"
import FloatingCTAShell from "@components/FloatingCTAShell/FloatingCTAShell"
import Layout from "@components/Layout/Layout"
import MoreNewsSlider from "@components/MoreNewsSlider/MoreNewsSlider"
import NewsBanner from "@components/NewsBanner/NewsBanner"
import NewsLetterModule from "@components/NewsLetterModule/NewsLetterModule"
import NewsRelatedContact from "@components/NewsRelatedContact/NewsRelatedContact"
import ShareIcons from "@components/SocialShare/ShareIcons"
import TeamContactModalWithTrigger from "@components/TeamContactModal/TeamContactModal"
import Animation from "@components/elements/Animation"
import GGFXImage from "@components/elements/GGFXImage"
import MailIcon from "@components/icons/MailIcon"
import MobileIcon from "@components/icons/MobileIcon"
import WhatsAppIcon from "@components/icons/WhatsAppIcon"
import Seo from "@components/seo"
import { aboutURL, newsAndInsightURL } from "@lib/urls"
import { addRollWhatsapp, getWhatsAppURL, gtagReportConversion, parseHTML } from "@lib/utils"
import "@styles/news-detail.scss"
import { Link, graphql, navigate } from "gatsby"
import { isEmpty } from "lodash"
import React from "react"
import { Container } from "react-bootstrap"
import logo from "@images/logo.svg"
import { Helmet } from "react-helmet"
const NewsDetailTemplate = ({ data }) => {
  const news = data.strapiBlog

  if (!news) {
    navigate("/404/")
  }

  const { title, modules, team_contact, strapi_id, slug } = news

  const breadcrumbURLs = [
    // {
    //   url: aboutURL,
    //   label: "About",
    // },
    {
      url: newsAndInsightURL,
      label: "News & Insights",
    },
    {
      url: "",
      label: title,
    },
  ]

  
  const pageurl = typeof window !== "undefined" ? window.location.href : ""
  var ldJson = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": process.env.GATSBY_SITE_URL+"/#organization",
        "name": "Provident Estate",
        "url": process.env.GATSBY_SITE_URL,
        "sameAs": [
          "https://www.facebook.com/providentestate",
          "https://www.instagram.com/providentestate",
          "https://www.linkedin.com/company/providentestate",
          "https://www.youtube.com/@Providentestate",
          "https://twitter.com/providentagents"
        ],
        "logo": {
          "@type": "ImageObject",
          "@id": process.env.GATSBY_SITE_URL+"/#logo",
          "inLanguage": "en-US",
          "url": logo,
          "contentUrl": logo,
          "width": 128,
          "height": 20,
          "caption": "Provident Estate"
        },
        "image": {
          "@id": process.env.GATSBY_SITE_URL+"/#logo"
        }
      },
      {
        "@type": "WebSite",
        "@id": process.env.GATSBY_SITE_URL+"/blog/#website",
        "url": process.env.GATSBY_SITE_URL+"/blog/",
        "name": "Provident Estate Blog UAE",
        "description": news?.short_description,
        "publisher": {
          "@id": process.env.GATSBY_SITE_URL+"/blog/#organization"
        },
        // "potentialAction": [
        //   {
        //     "@type": "SearchAction",
        //     "target": {
        //       "@type": "EntryPoint",
        //       "urlTemplate": process.env.GATSBY_SITE_URL+"/blog/?s={search_term_string}"
        //     },
        //     "query-input": "required name=search_term_string"
        //   }
        // ],
        "inLanguage": "en-US"
      },
      {
        "@type": "ImageObject",
        "@id": pageurl+"/#primaryimage",
        "inLanguage": "en-US",
        "url": news.banner_image?.url,
        "contentUrl": news.banner_image?.url,
        "width": 1000,
        "height": 500,
        "caption": news.title
      },
      {
        "@type": [
          "WebPage"
        ],
        "@id": pageurl+"/#webpage",
        "url": pageurl,
        "name": news.title +" | Provident Estate",
        "isPartOf": {
          "@id": process.env.GATSBY_SITE_URL+"/blog/#website"
        },
        "primaryImageOfPage": {
          "@id": pageurl+"/#primaryimage"
        },
        "datePublished": news?.createdAt,
        "dateModified": news?.updatedAt,
        "description": news?.short_description,
        "breadcrumb": {
          "@id": pageurl+"/#breadcrumb"
        },
       
        "inLanguage": "en-US",
        "potentialAction": [
          {
            "@type": "ReadAction",
            "target": [
              pageurl
            ]
          }
        ]
      },
      {
        "@type": "BreadcrumbList",
        "@id": pageurl+"/#breadcrumb",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": "Home",
            "item": process.env.GATSBY_SITE_URL
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": "News & Insights",
            "item": process.env.GATSBY_SITE_URL+"/blog/"
          },
          {
            "@type": "ListItem",
            "position": 3,
            "name": news?.title
          }
        ]
      },
      {
        "@type": "Article",
        "@id": pageurl+"/#article",
        "isPartOf": {
          "@id": pageurl+"/#webpage"
        },
        "author": {
          "@id": process.env.GATSBY_SITE_URL,
          "url": process.env.GATSBY_SITE_URL,
          "name": "Provident Estate",
        },
        "headline": news?.title,
        "datePublished": news?.createdAt,
        "dateModified": news?.updatedAt,
        "mainEntityOfPage": {
          "@id": pageurl+"/#webpage"
        },
        "wordCount": 1796,
        "publisher": {
          "@id": process.env.GATSBY_SITE_URL+"/blog/#organization"
        },
        "image": {
          "@id": pageurl+"/#primaryimage"
        },
        "thumbnailUrl": news.tile_image?.url,
        "articleSection": [
          "News & Insights"
        ],
        "inLanguage": "en-US"
      },
     
     
    ]
  }

  return (
    <>
      <Layout
        pageData={{
          page_class: "news-detail-page",
          layout: "news-detail",
        }}
      >
         <Helmet>
  <script type="application/ld+json">
    {JSON.stringify(ldJson, null, 2)}
  </script>
</Helmet>
        <AutoPopup />
        <Breadcrumbs staticURLs={breadcrumbURLs} />
        <Container className="news-detail-container">
          <div className="news-info-section">
            <NewsBanner news={news} />
            <Animation className="news-banner-img">
              <GGFXImage
                ImageSrc={news.banner_image}
                altText={title}
                imagetransforms={news.ggfx_results}
                renderer="srcSet"
                imagename="blog.banner_image.main_img"
                strapiID={news.strapi_id}
              />
            </Animation>
            {modules.map((module) => (
              <React.Fragment key={module.id}>
                {module.strapi_component === "components.rich-text-block" && (
                  <Animation className="news-content">
                    {parseHTML(module.text.data.text)}
                  </Animation>
                )}
                {module.strapi_component === "modules.ads-banner" && (
                  <AdsBanner smallBanner noContainer module={module} />
                )}
                {module?.strapi_component === "modules.faq" && (
                  <FAQ module={module} noContainer bgColor="white" />
                )}
              </React.Fragment>
            ))}
            <p className="more-info">
              For more information, get in touch with us at{" "}
              <Link to="/contact/">Provident </Link>
            </p>
            <ShareIcons isNews />
            <div className="divider" />
            {!isEmpty(team_contact) && (
              <>
                <NewsRelatedContact team={team_contact} news={news} />
                <div className="divider" />
              </>
            )}
            <NewsLetterModule />
          </div>
        </Container>
        <MoreNewsSlider strapiID={news.strapi_id} />
      </Layout>
      {team_contact && (
        <FloatingCTAShell>
          <TeamContactModalWithTrigger
            team={team_contact}
            className="button button-orange"
          >
            <span>Email</span>
            <MailIcon color="white" />
          </TeamContactModalWithTrigger>
          <a
            href={`tel:${team_contact.phone}`}
            className="button button-orange"
            onClick={() => gtagReportConversion(`tel:${team_contact.phone}`)}
          >
            <span>Call</span>
            <MobileIcon color="white" />
          </a>
          <a
            href={getWhatsAppURL(
              team_contact.phone,
              `Hi, I'm interested in ${title} - ${
                process.env.GATSBY_SITE_URL + newsAndInsightURL + slug
              }/`
            )}
            className="button button-gray whatsapp-link"
            target="_blank"
            rel="noreferrer"
            onClick={()=> addRollWhatsapp()}
            >
            <span>WhatsApp</span>
            <WhatsAppIcon />
          </a>
        </FloatingCTAShell>
      )}
    </>
  )
}

export default NewsDetailTemplate

export const Head = ({ data }) => {
  const news = data?.strapiBlog
  const imgUrl = news?.banner_image?.url
  const path = imgUrl?.split("amazonaws.com/")[1]
  // console.log(path)
  let myimgtransforms = ""
  if (news?.imagetransforms?.banner_image_Transforms) {
    myimgtransforms = JSON.parse(news?.imagetransforms?.banner_image_Transforms)
  }
  // console.log(myimgtransforms)
  let pageImg = ""

  if (Object.keys(myimgtransforms)?.length > 0) {
    pageImg = myimgtransforms[path]?.webp["696x"]
  }

  // if (Object.keys(myimgtransforms)?.length > 0) {
  //   let pageImg_filt = []
  //   for (const myimgtransform in myimgtransforms) {
  //     console.log(myimgtransform)
  //     if (typeof myimgtransforms[myimgtransform].webp !== "undefined") {
  //       pageImg_filt = Object.values(myimgtransforms[myimgtransform].webp)
  //       break
  //     }
  //   }
  //   // console.log(pageImg_filt)
  //   if (pageImg_filt.length > 0) pageImg = pageImg_filt[0]
  // }
  return (
    <Seo
      title={news?.seo?.metaTitle || news?.title}
      description={
        news?.seo?.metaDescription ||
        `Read about ${news?.title} here and subscribe to our newsletter to stay up-to-date about everything going on at Provident Real Estate.`
      }
      customCanonical={news?.seo?.canonicalURL}
      img={pageImg || news?.banner_image?.url}
    />
  )
}

export const query = graphql`
  query ($id: String) {
    strapiBlog(id: { eq: $id }) {
      ...blogFragment
    }
  }
`
